export const feature = [
    {
        icon: "/images/icon/task-square.svg",
        title: "Project Management",
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum varius pharetra mi. Ut tristique posuere.",
        button: true,
        box: true,
        // "btn_text": "Learn More"
    },
    {
        icon: "/images/icon/messages.svg",
        title: "Management",
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum varius pharetra mi. Ut tristique posuere.",
        button: true,
        box: true,
        // "btn_text": "Learn More"
    },
    {
        icon: "/images/icon/folder-open.svg",
        title: "Data Management",
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum varius pharetra mi. Ut tristique posuere.",
        button: true,
        box: true,
        // "btn_text": "Learn More"
    },
    {
        icon: "/images/icon/call-calling.svg",
        title: "Project",
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum varius pharetra mi. Ut tristique posuere.",
        button: true,
        box: true,
        // "btn_text": "Learn More"
    },
    {
        icon: "/images/icon/sms-tracking.svg",
        title: "Product",
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum varius pharetra mi. Ut tristique posuere.",
        button: true,
        box: true,
        // "btn_text": "Learn More"
    },
    {
        icon: "/images/icon/star.svg",
        title: "Data",
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum varius pharetra mi. Ut tristique posuere.",
        button: true,
        box: true,
        // "btn_text": "Learn More"
    }
]